import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    currentRouteKey: '',
    currentExportsCount: 0,
    planExportsCount: 0,
    planName: '',
};

export const commonSlice = createSlice({
    name: 'base/common',
    initialState,
    reducers: {
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload;
        },
        setCurrentExportsCount: (state, action) => {
            state.currentExportsCount = action.payload;
        },
        setPlanExportsCount: (state, action) => {
            state.planExportsCount = action.payload;
        },
        setPlanName: (state, action) => {
            state.planName = action.payload;
        },
    },
});

export const {
    setCurrentRouteKey,
    setCurrentExportsCount,
    setPlanExportsCount,
    setPlanName,
} = commonSlice.actions;

export default commonSlice.reducer;
