export const SIZES = {
    XS: 'xs',
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
};

export const CONTROL_SIZES = {
    [SIZES.XS]: 7,
    [SIZES.SM]: 9,
    [SIZES.MD]: 11,
    [SIZES.LG]: 14,
};

export const LAYOUT = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
    INLINE: 'inline',
};

export const SYNC_FREQUENCY = {
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
    DAILY: 'daily',
    IMMEDIATE: 'immediate',
};

export const segmentOptions = [
    { label: 'URL Path', value: 'URL_PATH' },
    { label: 'UTM Source', value: 'UTM_SOURCE' },
    { label: 'Company Name', value: 'COMPANY_NAME' },
    { label: 'UTM Campaign', value: 'UTM_CAMPAIGN' },
    { label: 'Date Interval', value: 'DATE_INTERVAL' },
    { label: 'Age Range', value: 'AGE_RANGE' },
    { label: 'Marital Status', value: 'MARTIAL_STATUS' },
    { label: 'Presence of Children', value: 'PRESENCE_OF_CHILDREN' },
    { label: 'Net Worth Range', value: 'NEW_WORTH_RANGE' },
    { label: 'Household Income Range', value: 'HOUSEHOLD_INCOME_RANGE' },
    { label: 'Homeowner Status', value: 'HOME_OWNER_STATUS' },
    { label: 'Education Level', value: 'EDUCATION_LEVEL' },
    { label: 'Occupation', value: 'OCCUPATION' },
    { label: 'Generation Group', value: 'GENERATION_GROUP' },
    { label: 'Gender', value: 'GENDER' },
];

export const conditionOptions = [
    { label: 'Contain', value: 'contain' },
    { label: 'Is', value: 'is' },
    { label: 'Is any', value: 'is_any' },
    { label: 'Is not', value: 'is_not' },
    { label: 'Does not contain', value: 'does_not_contain' },
];

export const DIRECTIONS = {
    TOP: 'top',
    RIGHT: 'right',
    BOTTOM: 'bottom',
    LEFT: 'left',
};

export const SELECTION_MODES = {
    YEAR: 'year',
    MONTH: 'month',
    DAY: 'day',
};

export const PICKER_VIEWS = {
    YEAR: 'year',
    MONTH: 'month',
    DATE: 'date',
};

export const STATUS = {
    DANGER: 'danger',
    SUCCESS: 'success',
    WARNING: 'warning',
};

export const STEPS_STATUS = {
    COMPLETE: 'complete',
    PENDING: 'pending',
    IN_PROGRESS: 'in-progress',
    ERROR: 'error',
};

export const PLACEMENT = {
    TOP_START: 'top-start',
    TOP_CENTER: 'top-center',
    TOP_END: 'top-end',
    BOTTOM_START: 'bottom-start',
    BOTTOM_CENTER: 'bottom-center',
    BOTTOM_END: 'bottom-end',
    MIDDLE_START_TOP: 'middle-start-top',
    MIDDLE_START_BOTTOM: 'middle-start-bottom',
    MIDDLE_END_TOP: 'middle-end-top',
    MIDDLE_END_BOTTOM: 'middle-end-bottom',
};

export const DROPDOWN_ITEM_TYPE = {
    DEFAULT: 'default',
    HEADER: 'header',
    DIVIDER: 'divider',
    CUSTOM: 'custom',
};

export const WEBSITE_PIXEL_STATUS = {
    READY: 'READY',
    IN_PROGRESS: 'IN_PROGRESS',
};

export const DAY_DURATION = 86400000;
