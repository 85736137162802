import ApiService from './ApiService';
export const apiDeletePaymentMethod = async (id) => {
    return ApiService.fetchData({
        url: 'client/delete-payment-method',
        method: 'delete',
        data: { paymentId: id },
    });
};

export const apiSaveKlaviyoApiKey = async (data) => {
    return ApiService.fetchData({
        url: 'klaviyo/private-key',
        method: 'post',
        data,
    });
};
export const apiGetKlaviyoOAuthUrl = async () => {
    return ApiService.fetchData({
        url: 'klaviyo/oauth/authorize',
        method: 'get',
    });
};
export const apiDisableKlaviyoIntegration = async () => {
    return ApiService.fetchData({
        url: 'klaviyo/disable-integration',
        method: 'patch',
    });
};
export const apiEnableKlaviyoIntegration = async () => {
    return ApiService.fetchData({
        url: 'klaviyo/enable-integration',
        method: 'patch',
    });
};
export const apiGetPaymentMethods = async (afterId) => {
    return ApiService.fetchData({
        url: afterId
            ? `client/payment-methods?after=${afterId}&limit=5`
            : `client/payment-methods?limit=5`,
        method: 'get',
    });
};

export const apiSetDefaultPaymentMethod = async ({ id }) => {
    return ApiService.fetchData({
        url: 'client/update-payment-method',
        method: 'post',
        data: { paymentId: id },
    });
};

export const apiGetBillingPlans = async () => {
    return ApiService.fetchData({
        url: 'client/retrieve-plans',
        method: 'get',
    });
};

export const apiRequestEarlyAccess = async ({ resource }) => {
    return ApiService.fetchData({
        url: `client/early-access?resource=${resource}`,
        method: 'get',
    });
};

export const apiGetKlaviyoAccounts = async () => {
    return ApiService.fetchData({
        url: 'klaviyo/accounts',
        method: 'get',
    });
};
export const apiGetMetaAuthUrl = async () => {
    return ApiService.fetchData({
        url: 'facebook-sdk/auth-url',
        method: 'get',
    });
};
export const apiSaveMetaAdAccount = async (data) => {
    return ApiService.fetchData({
        url: `audiences/save-adaccount?adaccount_id=${data.adaccount_id}&adaccount_name=${data.adaccount_name}&access_token=${data.access_token}&company_id=${data.company_id}&client_business_id=${data.client_business_id}`,
        method: 'get',
    });
};

export const apiSaveMetaAdAccountFromLogin = async (data) => {
    return ApiService.fetchData({
        url: 'audiences/save-ad-account-from-login',
        method: 'post',
        data,
    });
};

export const apiGetBusinessManagers = async (accessToken) => {
    return ApiService.fetchData({
        url: `/facebook-sdk/ad-accounts?access_token=${accessToken}`,
    });
};

export const apiSaveAdAccounts = async (accounts) => {
    return ApiService.fetchData({
        url: 'client/googleAd/save-ad-accounts',
        method: 'post',
        data: { accounts },
    });
};
export const apiGetIntegrationAdAccounts = async (code) => {
    return ApiService.fetchData({
        url: `client/googleAd/google-ad-accounts?code=${code}`,
        method: 'get',
    });
};
export const apiEnableGoogleIntegration = async () => {
    return ApiService.fetchData({
        url: 'client/googleAd/enable-google-ad',
        method: 'post',
    });
};
export const apiDisableGoogleIntegration = async () => {
    return ApiService.fetchData({
        url: 'client/googleAd/disable-google-ad',
        method: 'delete',
    });
};
export const apiGetGoogleAuthUrl = async () => {
    return ApiService.fetchData({
        url: 'client/googleAd/auth-url',
        method: 'get',
    });
};
export const apiGetCurrentAdAccounts = async () => {
    return ApiService.fetchData({
        url: 'client/googleAd/ad-accounts',
        method: 'get',
    });
};
export const apiGetCurrentClientData = async (params) => {
    return await ApiService.fetchData({
        url: 'client/current-client-data',
        method: 'get',
        params,
    });
};

export const apiGetClientCompanies = async () => {
    return await ApiService.fetchData({
        url: 'client/companies',
        method: 'get',
    });
};

export const apiGetSwitchCompany = async ({ company_id }) => {
    return await ApiService.fetchData({
        url: `client/switch-company?company_id=${company_id}`,
        method: 'get',
    });
};

export const apiDeclineCompany = async ({ company_id }) => {
    return await ApiService.fetchData({
        url: `client/decline-invitation?company_id=${company_id}`,
        method: 'post',
    });
};

export const apiAcceptCompany = async ({ company_id }) => {
    return await ApiService.fetchData({
        url: `client/accept-invitation?company_id=${company_id}`,
        method: 'post',
    });
};

export const apiCreateCompany = async ({ companyName }) => {
    return await ApiService.fetchData({
        url: 'client/create-company',
        method: 'post',
        data: { companyName },
    });
};

export const apiChangeClientPassword = async (data) => {
    return await ApiService.fetchData({
        url: `client/update-password/`,
        method: 'post',
        data,
    });
};

export const apiChangeClientGooglePassword = async ({
    idToken,
    newPassword,
}) => {
    return await ApiService.fetchData({
        url: `client/update-google-password`,
        method: 'post',
        headers: {
            idToken,
        },
        data: {
            newPassword,
        },
    });
};

export const apiGetBillingSession = async (data) => {
    return await ApiService.fetchData({
        url: `stripe/create-session?stripeCustomerId=${data.stripeCustomerId}`,
        method: 'get',
    });
};

export const apiCancelSupscription = async (data) => {
    return await ApiService.fetchData({
        url: `client/cancel-subscription`,
        method: 'post',
        data,
    });
};

export const apiUpgrageClientSubscription = async (data) => {
    return await ApiService.fetchData({
        url: `stripe/create-plan-change-checkout-session`,
        method: 'post',
        data,
    });
};

export const apiDowngradeClientSubscription = async (data) => {
    return await ApiService.fetchData({
        url: `client/downgrade-subscription`,
        method: 'post',
        data,
    });
};

export const apiChangeSubscription = async ({
    vendorPriceId,
    paymentId,
    promoCode,
}) => {
    const data = {
        vendorPriceId,
        paymentId,
        ...(promoCode ? { promoCode } : {}),
    };

    return await ApiService.fetchData({
        url: `client/change-subscription`,
        method: 'post',
        data,
    });
};

export const apiValidatePromoCode = async ({ code }) => {
    return await ApiService.fetchData({
        url: `client/validate-promo-code`,
        method: 'post',
        data: { code },
    });
};

export const apiAddPaymentMethod = async (data) => {
    return await ApiService.fetchData({
        url: `client/add-payment-method?return_url=${data.returnUrl}`,
        method: 'get',
    });
};

export const apiGetBillingHistory = async (data) => {
    return await ApiService.fetchData({
        url: `client/invoices-v2?lastInvoiceId=${data.lastInvoiceId}&limit=5`,
        method: 'get',
        data,
    });
};

export const apiUpdateClientProfile = async (data) => {
    return await ApiService.fetchData({
        url: `client/update-profile`,
        method: 'patch',
        Headers: {
            Connection: 'keep-alive',
            timeout: 240000,
        },
        data,
    });
};

export const apiIntegrateFacebook = async (data) => {
    return await ApiService.fetchData({
        url: 'client/integerate-facebook',
        method: 'post',
        data,
    });
};

export const apiChangeFacebookStatus = async (data) => {
    return await ApiService.fetchData({
        url: `client/change-facebook-status?company_id=${data.company_id}&fb_active=${data.fb_active}`,
        method: 'post',
    });
};

export const apiGetMetaAccessToken = async (data) => {
    return ApiService.fetchData({
        url: `facebook-sdk/access-token?code=${data.code}`,
        method: 'get',
    });
};

export const apiGetCompanyClients = async (params) => {
    return await ApiService.fetchData({
        url: 'client/get-company-clients?company_id=' + params.company_id,
        method: 'get',
    });
};

export const apiPostClientInvite = async (data) => {
    return await ApiService.fetchData({
        url: 'client/invite-client',
        method: 'post',
        data,
    });
};

export const apiUpdateClientAccess = async (data) => {
    return await ApiService.fetchData({
        url:
            'client/update-client-access?client_id=' +
            data.clientId +
            '&role=' +
            data.role,
        method: 'patch',
    });
};

export const apiDeleteMember = async (data) => {
    return await ApiService.fetchData({
        url: 'client/remove-member',
        method: 'patch',
        data,
    });
};

export const apiRegisterFromInvite = async (data) => {
    return await ApiService.fetchData({
        url: 'client/register-invited-client',
        method: 'post',
        data,
    });
};
